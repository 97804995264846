:root{
	--card-size: 6vw;
	--card-margin: 0.5vw;
	--big-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
	--shadow: 0 4px 16px 0 rgba(31, 38, 135, 0.175);
	--order-color: rgb(173, 233, 255);
	--bg-1: rgb(255, 255, 255);
	--bg-2: rgb(245, 245, 245);
	--bg-3: rgb(221, 221, 221);
}

.App{
	height: 100dvh;
	width: 100vw;
	font-family: "Poppins", sans-serif;
	overflow: hidden;
}

*::-webkit-scrollbar {
	display: none;
}